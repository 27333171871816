<template>
	<div class="qd-cookie-popup" v-if="showPopup">
		<div class="qd-cookie-popup__inner">
			<strong>
				Vi bruger cookies til statistik
			</strong>

			<p>
				Ved at klikke "Accepter", godkender du vores brug af cookies til statistik.</p>
			<p>
				<a class="link" :href="cookiePageUrl">
					Læs mere om cookies
				</a>
			</p>

			<div class="qd-cookie-popup__action">
				<a href="#" class="o-btn o-btn--filled js-qd-cookie-popup-accept" v-on:click="acceptCookies">Jeg accepterer</a>
				<a href="#" class="o-btn js-qd-cookie-popup-deny" v-on:click="declineCookies">Nej tak</a>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'cookie-popup',
        props: {
            cookiePageUrl: String,
			keyName: 'cookieConsent',
			maxAge: 31536000,
        },

		data() {
            return {
          		showPopup: false,
                pageviewAlreadySent: false
			}
		},

        methods: {
            acceptCookies: function (responder) {
                responder.preventDefault();
				this.showPopup = false
				this.setConsent(true, true)
            },
			declineCookies: function (responder) {
                responder.preventDefault();
				this.showPopup = false
                this.setConsent(false, true)
            },
			setConsent: function (consentState, persist) {
                var dataLayerVariable = {};

                if ( consentState === true && !this.pageviewAlreadySent ) {
                    dataLayerVariable.event = 'consentPageview';
                    this.pageviewAlreadySent = true;
                }

                // Set consent variable
                dataLayerVariable[ this.keyName ] = !!consentState;

                if (typeof(dataLayer) != "undefined"){
                    dataLayer.push(dataLayerVariable);
                }

                // Set the popup state
                this.setCookiePopupState( consentState );

                if ( persist && consentState !== null ) {
                    this.set( this.keyName, consentState );
                } else if ( consentState === null ) {
                    this.remove( this.keyName );
                }
            },
            setCookiePopupState: function(state){
                if(state === null){
                    state = false
				}

                this.showPopup = !state
			},
            get: function (keyName) {
                if ( localStorage ) {
                    var value = localStorage.getItem( keyName );
                    return value !== null ? JSON.parse( value ) : null;
                } else {
                    var match = document.cookie.match(new RegExp('(^| )' + keyName + '=([^;]+)'));
                    return match ? JSON.parse( match[ 2 ] ) : null;
                }
            },
			set: function (keyName,value) {
                if ( localStorage ) {
                    localStorage.setItem( keyName, value );
                } else {
                    document.cookie = keyName + '=' + value + ';max-age=' + this.maxAge;
                }
            },
			remove: function (keyName) {
                if ( localStorage ) {
                    localStorage.removeItem( keyName );
                } else {
                    document.cookie = keyName + '=; Max-Age=-99999999;';
                }
            }
        },

		mounted() {
			this.setConsent(this.get(this.keyName),true)
        }
    }
</script>
