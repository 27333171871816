// Import our CSS
import appStyles from '../scss/app.scss';
import uikit from '../scss/vendor/uikit.css';
import prolabel from '../scss/vendor/prolabel.css';
// import 'swiper/css/swiper.css'
import 'swiper/dist/css/swiper.css'
import styles from '../scss/vendor/styles.css';
// import 'swiper/swiper-bundle.css'

//Import vue components
import Vuex from 'vuex'

// import { Swiper as SwiperClass, Pagination, Navigation, EffectFade } from 'swiper';
import VueAwesomeSwiper from 'vue-awesome-swiper';

//Custom vue components
import CookiePopup from './components/CookiePopup.vue'
import Checkout from './components/Checkout.vue'
import 'promise-polyfill/src/polyfill';

import TrustpilotPlugin from "vue-trustpilot";

// App main
const main = async () => {
	// Async load the vue module
	const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

	const largeWidget = {
		templateId: "53aa8807dec7e10d38f59f32",
		businessunitId: "46a1681800006400050071ad",
		reviewUrl: "https://dk.trustpilot.com/review/www.camette.dk",
		styleHeight: "150px",
		styleWidth: "100%",
		locale: "da-dk"
	};

	const footerWidget = {
		templateId: "5419b732fbfb950b10de65e5",
		businessunitId: "46a1681800006400050071ad",
		reviewUrl: "https://dk.trustpilot.com/review/www.camette.dk",
		styleHeight: "24px",
		styleWidth: "100%",
		locale: "da-dk"
	};

	const productWidget = {
		templateId: "5419b732fbfb950b10de65e5",
		businessunitId: "46a1681800006400050071ad",
		reviewUrl: "https://dk.trustpilot.com/review/www.camette.dk",
		styleHeight: "24px",
		styleWidth: "100%",
		locale: "da-dk"
	};


	const options = {
		widgets: {
			largeWidget: largeWidget,
			footer: footerWidget,
			product: productWidget
		}
	};

	Vue.use(TrustpilotPlugin, options);


	//Enable Vuex store
	Vue.use(Vuex)

	//Enable Vue agile
	Vue.use(VueAwesomeSwiper);
	// SwiperClass.use([Pagination, Navigation, EffectFade]);

	//Vuex config and mutations
	const store = new Vuex.Store({
		state: {
			isMobileMenuOpen: false,
			scrollPosition: 0,
			droppoints: []
		},
		mutations: {
			closeMobileMenu(state) {
				state.isMobileMenuOpen = false
			},
			openMobileMenu(state) {
				state.isMobileMenuOpen = true
			},
			setDroppoints(state, value) {
				state.droppoints = value
			}
		}
	});

	// Create our vue instance
	const vm = new Vue({
		el: "#app",
		delimiters: ['${', '}'],
		store,
		components: {
			CookiePopup,
		},
		data() {
			return {
				showdroppoints: false
			}
		},
		methods: {
			toggleMobileMenu: function (event) {
				switch (store.state.isMobileMenuOpen) {
					case true:
						store.commit('closeMobileMenu')
						break

					case false:
						store.commit('openMobileMenu')
						break

					default:
						store.commit('closeMobileMenu')
						break
				}
			}
		},
		computed: {
			isMobileMenuOpen() {
				return store.state.isMobileMenuOpen
			},
			droppoints() {
				return store.state.droppoints
			}
		},
		created() {
		},
		destroyed() {
		},
		mounted() {
		},
	});

	return vm;
};

// Execute async function
main().then((vm) => {

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
