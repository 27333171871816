<script>
import checkout from "../../scss/vendor/onestepcheckout.css";

export default {
  name: "checkout",
  el: "#checkout",
  props: {},

  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
