var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPopup
    ? _c("div", { staticClass: "qd-cookie-popup" }, [
        _c("div", { staticClass: "qd-cookie-popup__inner" }, [
          _c("strong", [
            _vm._v("\n\t\t\tVi bruger cookies til statistik\n\t\t")
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              '\n\t\t\tVed at klikke "Accepter", godkender du vores brug af cookies til statistik.'
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c(
              "a",
              { staticClass: "link", attrs: { href: _vm.cookiePageUrl } },
              [_vm._v("\n\t\t\t\tLæs mere om cookies\n\t\t\t")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qd-cookie-popup__action" }, [
            _c(
              "a",
              {
                staticClass: "o-btn o-btn--filled js-qd-cookie-popup-accept",
                attrs: { href: "#" },
                on: { click: _vm.acceptCookies }
              },
              [_vm._v("Jeg accepterer")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "o-btn js-qd-cookie-popup-deny",
                attrs: { href: "#" },
                on: { click: _vm.declineCookies }
              },
              [_vm._v("Nej tak")]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }